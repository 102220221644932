
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { apiConfig } from '../../helpers/apiConfig';
import TimeAgo from 'react-timeago';
import './news.css';


const NewsList = (props) => {
    const {category} = props;
    const [newsList, setNewsData] = useState([]);
    const headers = apiConfig.apiAuthHeader;
    
    const categoryParm = (category !== 'all') ? '&categories='+category : '';
    
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    let mounted = false;
    
    
    const [prevOffset, setPrevOffset] = useState(0); 
    const [currentPage, updatePage] = useState(1);
    let totalPages = 1;
    const [pagination, setPagination] = useState(false);
    
    const [prevCategory, updateCategory] = useState(category);

    
    useEffect(() =>{

        
        if (!mounted) {
            mounted = true;
            
            if(prevCategory != category) {
                setOffset(0);
                setPrevOffset(0);
            }
            
            axios
            .get(apiConfig.apiBaseUrl +'news-list?limit='+limit+'&offset='+offset+categoryParm, { headers })
            .then(response => {
                totalPages = response.data.total_pages;
                if(totalPages > 1){
                    if(totalPages == currentPage){
                        setPagination(false);
                    }else{
                        setPagination(true);
                    }

                }
                else{
                    setPagination(false);
                }

                
                if(prevOffset === offset){                    
                    return setNewsData(response.data.news_list)
                }
                else{

                    return setNewsData((prevList) => prevList.concat(response.data.news_list))
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
    },[category, offset]);

    function loadMore(){
        updatePage((curPage) => curPage+1);
        updateCategory(category);
        setPrevOffset(offset);
        setOffset(offset+limit);
    }

    

    return(
       <>
       <div className="news-list-wrapper">
            <div className="container-wrap">
                {(!(newsList.length > 0)) ? 
                    <h3 className="">Fetching News. Please Wait!</h3>
                : 
                <>
                    <ul>                    
                        {
                            (newsList.length > 0) ? newsList.map((item, index) => {
                                    const categoryList = (item.categories.length > 0) ? item.categories.map((catItem, catIndex) => {
                                        return catItem.category_name;
                                    }) : [];
                                    
                                    return(
                                        <li key={index}>
                                        <NewsItem 
                                            
                                            id={item.id}
                                            link={true}
                                            title={item.title} 
                                            slug={item.slug}
                                            duration={item.timestamp * 1000} 
                                            // category={item.categories[0]?.category_name}
                                            category={categoryList?.join(", ")}
                                            thumbnail={false}
                                        >
                                            {item.excerpt}
                                        </NewsItem>
                                        </li>
                                    )
                                })
                                : ''                        
                        }                    
                    </ul>
                    {
                        (pagination) ? (
                            <div className="load-more-wrapper">
                                <button className="btn btn-primary load-more" onClick={loadMore}>Load More</button>
                            </div>  
                        )
                        :
                        ''
                    }
                                  
                </>
                }
            </div>
       </div>
       </> 
    );

}



export default NewsList;

const NewsItem = (props) => {
    const {title, category, slug, duration, link, id, thumbnail} = props;
    const newsId = id;
    // console.log(duration);
    

    let newsTitle = <div><h3 className="news-title">{title}</h3><p className="news-excerpt" dangerouslySetInnerHTML={{__html: props.children}}/></div>;
    if(link){
        newsTitle = <Link to={"/news/"+slug} state={ newsId }>{newsTitle}</Link>;
    }

    let newsThumbnail = '';
    if(thumbnail){
        newsThumbnail = <div className="news-thumbnail"><img src={thumbnail} alt={title}/></div>
    }
    return(
        <div className="news-item-card">
            {newsThumbnail}
            {newsTitle}
            <div className="news-item-footer">
                <div className="news-footer-left">
                    <h6 className="news-category">{category}</h6>
                    <h6 className="news-duration">
                        <TimeAgo date={duration} />
                    </h6>
                </div>
                <div className="news-footer-right">

                </div>
            </div>
        </div>
    );
};

export {NewsItem};