import React, { useState, useEffect, useRef } from 'react';

import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode} from 'swiper/modules';
import 'swiper/css';

import axios from 'axios';


import { BrowserRouter, Routes, Route, Link, NavLink } from 'react-router-dom';

import { Home } from '../../pages/Home';
import { About } from '../../pages/About';
import { News } from '../../pages/News';
import { Category } from '../../pages/Category';
import { apiConfig } from '../../helpers/apiConfig';
import AppLogo from './Logo';



const Header = () => {
    
    const swiperRef = useRef();

    const [categoryList, setData] = useState([]);
    const headers = apiConfig.apiAuthHeader;

    
    

    useEffect(() =>{
        axios
        .get(apiConfig.apiBaseUrl +'category-list', { headers })
        .then(response => setData(response.data))
        .catch(err => {
            console.error(err);
        });
    },[]);


    
    
    


    return (<>
            <BrowserRouter>
                <div className="header">
                    <div className="top-bar">
                        <div className="container-wrap">
                            <div className="TopBarContainer">
                                <div className="logo-wrap">
                                    
                                        <Link to="/">
                                            <AppLogo/>
                                        </Link>
                                    
                                </div>
                                <div className="HeaderInner_menu_icon__6bTkM">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                    <div className="container-wrap">
                        <div className="nav-container">
                            <nav>
                                <div className="nav nav-main">
                                    {
                                        
                                    }
                                    <Swiper 
                                        modules={[FreeMode]}
                                        spaceBetween={15}
                                        slidesPerView="auto"
                                        centeredSlides={0}
                                        freeMode={{ "enabled": true }}
                                        onSwiper={(swiper) => {
                                            swiperRef.current = swiper;
                                        }}
                                        
                                    >
                                        
                                        <SwiperSlide>        
                                            <NavLink to="/" className={({isActive}) => {
                                                if(isActive) {
                                                    swiperRef?.current?.slideTo(-2);
                                                    
                                                }
                                                return isActive ? 'active-nav-link' : '';
                                            }}>
                                                Home
                                            </NavLink>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>
                                            <NavLink to="/about" className={({isActive}) => {
                                                if(isActive) {
                                                    swiperRef?.current?.slideTo(-1);
                                                    
                                                }
                                                return isActive ? 'active-nav-link' : '';
                                            }}>About</NavLink>
                                        </SwiperSlide> */}
                                        
                                        {                                               
                                            categoryList.map((item, index) => {
                                                
                                                return(
                                                     <SwiperSlide key={index} >
                                                        <NavLink to={"/category/"+item.category_slug} state={ item.category_name } className={({isActive}) => {
                                                            if(isActive) {
                                                                swiperRef.current.slideTo(index);
                                                                
                                                            }

                                                            return isActive ? 'active-nav-link' : '';
                                                        }}>
                                                            {item.category_name}
                                                        </NavLink>
                                                    </SwiperSlide>
                                                )
                                            })
                                            
                                        }
                                    </Swiper>
                                        
                                </div>
                                
                            </nav>
                        </div>
                    </div>                    
                </div>                        
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/about" element={<About />} /> */}
                    <Route path="/news/:newsSlug" element={<News state="0" />} />
                    <Route path="/category/:categorySlug" element={<Category state=""/>} />
                </Routes>
            </BrowserRouter>
            </> 
    );
}

export default Header;