import React from 'react';
import { ReactComponent as MySvgAppLogo } from '../../assets/short-news-logo.svg';


const AppLogo = () => {
  return (
    <div>
      <MySvgAppLogo />
    </div>
  );
}

export default AppLogo;

