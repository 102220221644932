import React, {useState, useEffect} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {NewsItem} from '../components/news/news';
import { apiConfig } from '../helpers/apiConfig';
import axios from 'axios';

export const News = ( props ) => {
    const {newsSlug} = useParams();
    const newsID = useLocation().state;
    const [newsData, setNewsData] = useState({});
    const headers = apiConfig.apiAuthHeader;
    
    useEffect(() =>{
        axios
        .get(apiConfig.apiBaseUrl +'news-detail?news-id='+newsSlug, { headers })
        .then(response => setNewsData(response.data))
        .catch(err => {
            console.error(err);
            return '<h3 className="">Sorry no data available!</h3>';
        });
    },[newsID]);

    // console.log(newsData);
    

    return (
      <>
        {/* <div className="container-wrap">

            <h1 className="page-template-news">This is detailed news!</h1>
            <p>Slug: {newsSlug}</p>
            <p>ID: {newsID}</p>
        </div> */}
        <div className="news-detail-wrapper">
            <div className="container-wrap">
                {
                    (Object.keys(newsData).length !== 0 && newsData.title !== undefined) ? 
                        <NewsItem 
                            link={false}
                            title={newsData.title} 
                            duration={newsData.timestamp * 1000} 
                            category={(newsData.categories) ? newsData.categories[0].category_name : ""}
                            thumbnail={(newsData.thumbnail !== "") ? newsData.thumbnail : false}
                        >

                            {newsData.excerpt + " " + atob(newsData.content)}
                        </NewsItem>
                       
                    : 
                    <h3 className="">Fetching News. Please Wait!</h3>
                }
                
            </div>
        </div>
      </>
    );
}


