import React from 'react';


import NewsList from '../components/news/news';


export const Home = () => {
  
  

  return (
    <>
      
      <div className="App">
          
          <NewsList category="all"/>

      </div>
    </>
  );
}


