import React from 'react';
import './App.css';
import Header from './components/utils/Header';
// import AccordionWrap from './components/accordion/accordionWrap';


function App() {
  
  

  return (
    <>
      <Header/>
      
    </>
  );
}

export default App;
