import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import NewsList from '../components/news/news';
// import { apiConfig } from '../helpers/apiConfig';
// import TimeAgo from 'react-timeago';

export const Category = ( props ) => {
    const {categorySlug} = useParams();
    const categoryName = useLocation().state;
    // const [value, setValue] = useState(categorySlug);
    // console.log(categorySlug);

    return (
      <>
        <div className="container-wrap">

            <h1 className="page-template-news">{categoryName} News</h1>
        </div>
        <NewsList category={categorySlug} />
      </>
    );
}


